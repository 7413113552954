import Vue from 'vue'
import moment from 'moment'

Vue.filter('date', (value, format = 'LLL') => {
  const f = format === 'PD' ? 'dddd, MMMM D YYYY hh:mm:ss A' : format
  return value ? moment(value).format(f) : 'N/A'
})

Vue.filter('dateServer', (value, format = 'LLL') => {
  return value ? moment(value).add(window.serverDiff, 's').format(format) : 'N/A'
}) 

// Vue.filter('role', (value) => window.roles[value])

Vue.filter('money', (value) => {
  return window.formatMoney(value)
})

Vue.filter('balance', (value) => {
  return window.formatMoney(value)
})

Vue.filter('balance_without_symbol', (value) => {
  return Intl.NumberFormat('en-US', { minimumFractionDigits: 2 }).format(value)
})

Vue.filter('assets_balance', (value) => {
  return Intl.NumberFormat('en-US', { minimumFractionDigits: 6 }).format(value)
})

Vue.filter('unit_balance', (value) => {
  return Intl.NumberFormat('en-US', { minimumFractionDigits: 4 }).format(value)
})

Vue.filter('uppercase', (value) => value.toUpperCase())

Vue.filter('capitalize', (value) => {
  if (typeof value !== 'string') return ''
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
})

Vue.filter('yesNo', (value) => value ? 'Yes' : 'No')

Vue.filter('fileSize', (size) => {
  size = parseInt(size)
  if (!size) return '0 kB'
  const i = Math.floor( Math.log(size) / Math.log(1024) );
  return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
})


Vue.filter('formattedTwoDecimal', (value) => {
    return (value/1).toFixed(2)
})

Vue.filter('country', (value) => window.countriesOptions[value])

Vue.filter('state', (value) => {
  if (!value) return 'N/A'
  const states = window.states.CA.concat(window.states.US)
  const state = states.find(item => item.id === value)
  return state?.text || value
})

Vue.filter('sinNum', (value) => {
  var filler=Array(100).join("*");
  if(value) 
  return `${filler.substring(0,value.length-4)}${value.slice(-4)}`
})


Vue.filter('unit_asset_balance', (value) => {
  return Intl.NumberFormat('en-US', { maximumFractionDigits: 10 }).format(value)
})

<template>
  <div>
    <section>
      <div class="flex-between" style="position: relative;">
        <div style="padding: 24px 30px 10px 10px;">
          <el-popover
            placement="bottom-start"
            width="500"
            popper-class="paddingless-popper"
            trigger="click">
            <table class="summary-table smaller">
              <tr>
                <td>Deposits</td>
                <td class="text-center">{{totalFundsDepositNumber}}</td>
                <td class="text-right">${{ totalDepositAmount | balance_without_symbol}}</td>
              </tr>
              <tr>
                <td>Withdrawals</td>
                <td class="text-center">{{totalFundsWithdrawalNumber}}</td>
                <td class="text-right">${{ totalWithdrawalAmount | balance_without_symbol}}</td>
              </tr>
            </table>
            <button slot="reference" class="btn btn-secondary"><i class="el-icon-tickets" /> Summary</button>
          </el-popover>
          <button v-if="activeInfoReversals" class="btn btn-secondary" @click="activeInfo = !activeInfo , activeInfoReversals = false, page=1"> Reporting ({{ totalNumber }})</button>
          <button v-if="activeInfo" class="btn btn-secondary" @click="activeInfoReversals = !activeInfoReversals, activeInfo = false, page=1"> Reversals ({{ totalReversals }})</button>
        </div>
        <div style="padding: 24px 30px 10px 10px;">
          <template v-if="totalNumber > 0">
            <button v-if="filters" class="btn second" @click="downloadModal = true"><i class="el-icon-download" />Download</button>
            <button v-else class="btn second" @click="downloadModal = true"><i class="el-icon-download" />Download</button>
          </template>
          
          <button class="second" @click="openedFilters = true"><i class="el-icon-s-operation" /> Filters</button>
        </div>
      </div>
    </section>

    <applied-filters :filters="appliedFilters" @clear="clearFilters()" @remove="removeFilter($event)" />

    <section v-if="$hasPerm('update-transaction')"> 
      <div class="text-center" v-if="currentUser.role && currentUser.role.group.id==1">
        <button v-if="systemMaintenanceStatus" class="main forcebtt" @click="updateTrasactions()">Update Transactions</button>
      </div> 
    </section>
    
    <div class="accordion">
      <div :class="{'trans-sub-cof-box' : true, 'cof-box-active' : activeInfo}">
        <section  class="content-tlist" v-if="!loading">
          <el-table class="content-table" :data="filteredData" @row-click="transactionsTableClick" ref="transactionsTable">
            <el-table-column type="expand">
              <template slot-scope="props">
                <div class="popup-header" v-for="selectedTransaction in [[props.row]]">
                  <h3>Transaction</h3>
                  <h3 v-if="selectedTransaction">{{ selectedTransaction[0].transactionId }}</h3>
                  <h3 v-if="selectedTransaction">Status: {{ selectedTransaction[0].status }}</h3>
                </div>
                <template v-for="selectedTransaction in [[props.row]]">
                  <div class="scrollable">
                    <div class="information">
                      <template v-if="selectedTransaction">
                        <div>
                          <p><b>Account</b>: ****{{ selectedTransaction[0].accountId.substr(-3) }}</p>
                          <p><b>Created</b>: {{ selectedTransaction[0].createdAt }}</p>
                          <p><b>Entry Type</b>: {{ selectedTransaction[0].entryType }}</p>
                          <p><b>Transaction Amount</b>:
                            <template v-if="selectedTransaction[0].currencyType == 'Assets'">{{ selectedTransaction[0].assetAmount ? parseFloat(selectedTransaction[0].assetAmount) : '' }}</template>
                            <template v-else>{{ selectedTransaction[0].amount | balance }}</template>
                          </p>
                        </div>
                        <div>
                          <p><b>Completed By</b>: {{ selectedTransaction[0].completedBy }}</p>
                          <p><b>Transaction Type</b>: {{ transactionTypeText[selectedTransaction[0].transactionType] }}</p>
                        </div>
                      </template>
                    </div>
                    <div class="popup-header details">
                      <h3>Details</h3>
                    </div>
                      <template v-if="selectedTransaction">
                        <template v-if="selectedTransaction[0].entryType == 'Deposit' && selectedTransaction[0].transactionType == 'Asset'">
                          <p><b>{{ getCurrencyName(selectedTransaction[0].currencyName) }} Deposit Wallet Address</b>:</p>
                          <p>{{ selectedTransaction[0].contributionIncomingWalletAddress }}</p>
                          <p>&nbsp;</p>
                        </template>
                        <template v-if="selectedTransaction[0].entryType != 'Deposit' && selectedTransaction[0].transactionType == 'Asset'">
                          <p><b>Pay to</b>: {{ selectedTransaction[0].label }}</p>
                          <p><b>Pay to Wallet Address</b>: {{ selectedTransaction[0].outgoingWalletAddress }}</p>
                          <p><b>Destination Tag</b>: {{ selectedTransaction[0].tag }}</p>
                          <p>&nbsp;</p>
                        </template>
                        <div class="information">
                          <div>
                            <template v-if="selectedTransaction[0].entryType == 'Deposit'">
                              <template v-if="selectedTransaction[0].transactionType == 'checks'">
                                <p><b>Payer</b>: <template v-if="selectedTransaction[0].remoteDepositPayor">{{ selectedTransaction[0].remoteDepositPayor.nameOfSender }}</template></p>
                                <br/>
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].remoteDepositPayor">
                                  <p>{{ selectedTransaction[0].remoteDepositPayor.address }}</p>
                                  <p>{{ selectedTransaction[0].remoteDepositPayor.city }}, {{ selectedTransaction[0].remoteDepositPayor.state }}, {{ selectedTransaction[0].remoteDepositPayor.country }}, {{ selectedTransaction[0].remoteDepositPayor.postal }}</p>
                                </template>
                                <br/>
                                <p><b>Check Front</b>: <a class="image-link" :href="`/files/transactions/${selectedTransaction[0].id}/${selectedTransaction[0].checkImageFront}`" target="_blank">{{ selectedTransaction[0].checkImageFront }}</a></p>
                                <p><b>Check Bank</b>: <a class="image-link" :href="`/files/transactions/${selectedTransaction[0].id}/${selectedTransaction[0].checkImageBack}`" target="_blank">{{ selectedTransaction[0].checkImageBack }}</a></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'Asset'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                <br/>
                                <p><b>Note</b>: Send only {{ getCurrencyName(selectedTransaction[0].currencyName) }} to this wallet address. Any other asset type sent may be lost and unrecoverable.</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'acc_to_acc'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'DebitCard'">
                                <p><b>MERCHANT</b></p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantName }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCity }}, {{ selectedTransaction[0].merchant.merchant.merchantState }}, {{ selectedTransaction[0].merchant.merchant.merchantCountry }}, {{ selectedTransaction[0].merchant.merchant.postalCode }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCategory }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else>
                                <template v-if="selectedTransaction[0].provider == 'Solid' || selectedTransaction[0].provider == 'Solid Test'">
                                  <template v-if="selectedTransaction[0].transactionType == 'ach'">
                                    <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                    <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                    <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">name: {{ selectedTransaction[0].settlementDetails.name }}, routingNumber: {{ selectedTransaction[0].settlementDetails.routingNumber }}, bankName: {{ selectedTransaction[0].settlementDetails.bankName }}</template></p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                    <br/>
                                    <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                    <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                  </template>
                                  <template v-else-if="selectedTransaction[0].transactionType == 'domesticWire' || selectedTransaction[0].transactionType == 'internationalWire'">
                                    <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }} name: {{ selectedTransaction[0].settlementDetails.name }}</template></p>
                                    <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                    <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">routingNumber: {{ selectedTransaction[0].settlementDetails.routingNumber }}, Address Line1: {{ selectedTransaction[0].settlementDetails.line1 }}, Address Line2: {{ selectedTransaction[0].settlementDetails.line2 }}, city: {{ selectedTransaction[0].settlementDetails.city }}, state: {{ selectedTransaction[0].settlementDetails.state }}, country: {{ selectedTransaction[0].settlementDetails.country }}, postal: {{ selectedTransaction[0].settlementDetails.postalCode }}, bankName: {{ selectedTransaction[0].settlementDetails.bankName }}</template></p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                    <br/>
                                    <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                    <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                  </template>
                                  <template v-else>
                                    <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                    <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                    <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                    <br/>
                                    <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                    <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                  </template>
                                </template>
                                <template v-else>
                                  <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                  <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                  <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                  <p>&nbsp;</p>
                                  <p>&nbsp;</p>
                                  <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                  <br/>
                                  <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                  <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                </template>
                              </template>
                            </template>
                            <template v-else>
                              <template v-if="selectedTransaction[0].transactionType == 'international_wires' || selectedTransaction[0].transactionType == 'internationalWire'">
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].bankName }}</p>
                                <p><b>Swift</b>: {{ selectedTransaction[0].swift }}</p>
                                <br/>
                                <p><b>INTERMEDIARY BANK</b></p>
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].intermediaryBankName }}</p>
                                <p><b>Routing</b>: {{ selectedTransaction[0].intermediaryBankRoutingNumber }}</p>
                                <p><b>SWIFT</b>: {{ selectedTransaction[0].intermediaryBankSwiftCode }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].intermediaryBankReference }}</p>
                                <p><b>Address</b>:</p>
                                <p>{{ selectedTransaction[0].intermediaryAddress.address }}</p>
                                <p>{{ selectedTransaction[0].intermediaryAddress.city }}, {{ selectedTransaction[0].intermediaryAddress.state }}, {{ selectedTransaction[0].intermediaryAddress.country }}, {{ selectedTransaction[0].intermediaryAddress.postal }}</p>
                                <br/>
                                <p><b>BENEFICIARY</b></p>
                                <p><b>Account Name</b>: {{ selectedTransaction[0].accountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].accountNumber }}</p>
                                <p><b>Account Type</b>: {{ selectedTransaction[0].accountType }}</p>
                                <!--<p><b>IBAN</b>: {{ selectedTransaction[0].iban }}</p>-->
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].address">
                                  <p>{{ selectedTransaction[0].address.address }}</p>
                                  <p> {{ selectedTransaction[0].address.city }}, {{ selectedTransaction[0].address.state }}, {{ selectedTransaction[0].address.country }}, {{ selectedTransaction[0].address.postal }}</p>
                                </template>
                                <br/>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'checks'">
                                <p><b>Payee</b>: {{ selectedTransaction[0].payee }}</p>
                                <br/>
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].address">
                                  <p>{{ selectedTransaction[0].address.address }}</p>
                                  <p>{{ selectedTransaction[0].address.city }}, {{ selectedTransaction[0].address.state }}, {{ selectedTransaction[0].address.country }}, {{ selectedTransaction[0].address.postal }}</p>
                                </template>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'ach'">
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].bankName }}</p>
                                <p><b>Routing</b>: {{ selectedTransaction[0].routingNumber }}</p>
                                <br/>
                                <p><b>BENEFICIARY</b></p>
                                <p><b>Account Name</b>: {{ selectedTransaction[0].accountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].accountNumber }}</p>
                                <p><b>Account Type</b>: {{ selectedTransaction[0].accountType }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'domestic_wire' || selectedTransaction[0].transactionType == 'domesticWire'">
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].bankName }}</p>
                                <p><b>Routing Number</b>: {{ selectedTransaction[0].routingNumber }}</p>
                                <br/>
                                <p><b>BENEFICIARY</b></p>
                                <p><b>Account Name</b>: {{ selectedTransaction[0].accountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].accountNumber }}</p>
                                <p><b>Account Type</b>: {{ selectedTransaction[0].accountType }}</p>
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].address">
                                  <p>{{ selectedTransaction[0].address.address }}</p>
                                  <p>{{ selectedTransaction[0].address.city }}, {{ selectedTransaction[0].address.state }}, {{ selectedTransaction[0].address.country }}, {{ selectedTransaction[0].address.postal }}</p>
                                </template>
                                <br/>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'Asset'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'acc_to_acc'">
                                <p><b>Account Name</b>: {{ selectedTransaction[0].toAccountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].toAccountId? getAccountNumber(selectedTransaction[0].toAccountId) : '' }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'Trade'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'System'">
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'DebitCard'">
                                <p><b>MERCHANT</b></p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantName }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCity }}, {{ selectedTransaction[0].merchant.merchant.merchantState }}, {{ selectedTransaction[0].merchant.merchant.merchantCountry }}, {{ selectedTransaction[0].merchant.merchant.postalCode }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCategory }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else>
                                <p><b>Account Number</b>: ****{{ selectedTransaction[0].toAccountId? selectedTransaction[0].toAccountId.substr(4, 3) : '' }}
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                            </template>
                          </div>
                          <div>
                            <template v-if="selectedTransaction[0].entryType == 'Deposit'">
                              <template v-if="selectedTransaction[0].transactionType == 'domestic_wire' || selectedTransaction[0].transactionType == 'international_wires' || selectedTransaction[0].transactionType == 'ach' || selectedTransaction[0].transactionType == 'Asset' || selectedTransaction[0].transactionType == 'acc_to_acc' || selectedTransaction[0].transactionType == 'Trade' || selectedTransaction[0].transactionType == 'domesticWire' || selectedTransaction[0].transactionType == 'internationalWire'">

                              </template>
                              <template v-else>
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                              </template>
                            </template>
                            <template v-else>
                              <template v-if="selectedTransaction[0].transactionType == 'Asset' || selectedTransaction[0].transactionType == 'Trade'">

                              </template>
                              <template v-else>
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                              </template>
                            </template>
                          </div>
                        </div>
                      </template>
                    <!-- Place for details -->
                    <div class="popup-header notes">
                      <h3>Notes</h3>
                      <button v-if="addNote" class="action-button save add-note-button" @click="saveNote(selectedTransaction[0])">Save</button>
                    </div>
                    <div class="content-form" v-if="addNote">
                      <div class="form-row form-max">
                        <form-field-text v-model="note" />
                      </div>
                    </div>
                    <table class="content-table">
                      <tr>
                        <th style="padding-left: 0;">Date/Time</th>
                        <th>Added By</th>
                        <th>Note</th>
                      </tr>
                      <template v-if="selectedTransaction">
                        <tr v-for="(item, i) in selectedTransaction[0].notes" :key="item.id">
                          <td style="padding-left: 0;"><span>Date/Time</span>{{ item.noteTime | date }}</td>
                          <td><span>Added By</span>{{ item.addedBy }}</td>
                          <td><span>Note</span>{{ item.note }}</td>
                        </tr>
                      </template>
                    </table>
                  </div>
                  <div class="popup-header notes">
                    <h3>Options</h3>
                  </div>
                  <div class="buttons">
                    <div class="optionBtns" v-if="selectedTransaction">
                      <template v-if="$hasPerm('approve-transaction')">
                        <button v-if="!isCustomer && selectedTransaction[0].status === 'Review'" class="approve" @click="approvePopupTransaction(selectedTransaction[0].id)">Approve</button>
                      </template>
                      <template v-if="!isCustomer">
                        <template v-if="perms">
                          <button v-if="selectedTransaction[0].status === 'Pending' || selectedTransaction[0].status === 'Review'" class="cancel cancel-action" @click="cancelPopupTransaction(selectedTransaction[0].id)">Cancel</button>
                        </template>
                      </template>
                      <template v-else>
                        <button v-if="selectedTransaction[0].status === 'Pending' || selectedTransaction[0].status === 'Review'" class="cancel cancel-action" @click="cancelPopupTransaction(selectedTransaction[0].id)">Cancel</button>
                      </template>
                    </div>
                    <button class="main" @click="print(selectedTransaction[0].id)">Print</button>
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="Date" min-width="7">
              <template slot-scope="props">
                <p>{{ props.row.createdAt | date('MM/D/YYYY') }}<br/>
                {{ props.row.createdAt | date('hh:mm A') }}</p>
              </template>
            </el-table-column>
            <el-table-column label="Account" min-width="10">
              <template slot-scope="props">
                <a v-if="!isCustomer" :href="`/system-customers/registrations/${props.row.regId}?tab=Accounts`">{{ props.row.accountId }}</a>
              </template>
            </el-table-column>
            <el-table-column label="Number" min-width="7">
              <template slot-scope="props">
                {{ props.row.transactionId }}
              </template>
            </el-table-column>
            <!-- <el-table-column label="Entry" min-width="6">
              <template slot-scope="props">
                {{ props.row.entryType == 'Deposit'? 'DEP' : 'W/D' }}
              </template>
            </el-table-column> -->
            <el-table-column label="Type" min-width="8">
              <template slot-scope="props">
                {{ transactionTypeText[props.row.transactionType] }}
              </template>
            </el-table-column>
        <!--    <el-table-column
              label="Currency" min-width="6"
              prop="currencyName">
            </el-table-column> -->
            <el-table-column label="Amount" min-width="12">
              <template slot-scope="props">
                <template v-if="props.row.currencyType == 'Assets'">
                  <template v-if="props.row.entryType == 'Deposit'">
                    <label style="color: green;">+ {{ parseFloat(props.row.assetAmount) }}</label>
                  </template>
                  <template v-else>
                    <label style="color: red;">- {{ parseFloat(props.row.assetAmount) }}</label>
                  </template>
                </template>
                <template v-else>
                  <template v-if="props.row.entryType == 'Deposit'">
                    <label style="color: green;">+ {{ props.row.currencySymbol }} {{ props.row.amount | balance_without_symbol }}</label>
                  </template>
                  <template v-else>
                    <label style="color: red;">- {{ props.row.currencySymbol }} {{ props.row.amount | balance_without_symbol }}</label>
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="Balance" min-width="12">
              <template slot-scope="props">
                <template v-if="props.row.currencyType == 'Assets'">{{ parseFloat(props.row.availableAssetsBalance) }}</template>
                <template v-else>{{ props.row.currencySymbol }} {{ props.row.availableFundsBalance | balance_without_symbol }}</template>
              </template>
            </el-table-column>
            <el-table-column label="Status" min-width="7">
              <template slot-scope="props">
                <em :class="statusColorClasses[props.row.status]">{{ props.row.status }}</em>
              </template>
            </el-table-column>
            <el-table-column
              label="Reference" min-width="17"
              prop="reference">
            </el-table-column>
            <el-table-column label="Options" min-width="14">
              <template slot-scope="props">
                <template v-if="$hasPerm('approve-transaction')">
                  <template v-if="props.row.provider == 'Solid' || props.row.provider == 'Solid Test'">
                    <button v-if="!isCustomer && props.row.status === 'Review'" class="action-button save" @click="approveSolidTransaction(item, props.row.id)">Approve</button>
                  </template>
                  <template v-else>
                    <button v-if="!isCustomer && props.row.status === 'Review'" class="action-button save" @click="approveTransaction(props.row.id)">Approve</button>
                  </template>
                </template>
                <template v-if="props.row.provider == 'Prime Trust' || props.row.provider == 'Prime Trust Test'">
                  <template v-if="!isCustomer">
                    <template v-if="perms">
                      <template v-if="props.row.transactionType == 'Asset'">
                        <button v-if="props.row.status === 'Pending' || props.row.status === 'Review'" class="action-button cancel" @click="cancelAssetTransaction(props.row.id)">Cancel</button>
                      </template>
                      <template v-else>
                        <button v-if="props.row.status === 'Pending' || props.row.status === 'Review'" class="action-button cancel" @click="cancelTransaction(props.row.id)">Cancel</button>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="props.row.transactionType == 'Asset'">
                      <button v-if="props.row.status === 'Pending' || props.row.status === 'Review'" class="action-button cancel" @click="cancelAssetTransaction(props.row.id)">Cancel</button>
                    </template>
                    <template v-else>
                      <button v-if="props.row.status === 'Pending' || props.row.status === 'Review'" class="action-button cancel" @click="cancelTransaction(props.row.id)">Cancel</button>
                    </template>
                  </template>
                  <template v-if="props.row.status === 'Pending' && props.row.disbursementAuthorizationsStatus === 'pending'">
                    <button v-if="!isCustomer" class="action-button cancel" @click="authRetry(props.row.id)">Auth</button>
                    <button v-else class="action-button cancel" disabled>Auth</button>
                  </template>
                </template>
                <template v-else-if="props.row.provider == 'Think Green'">
                  <button v-if="props.row.status === 'Pending'" class="action-button cancel" @click="cancelTransaction(props.row.id)">Cancel</button>
                  <button v-if="props.row.status === 'Review'" class="action-button cancel" @click="cancelTransaction(props.row.id)">Cancel</button>
                </template>
                <template v-else>
                  <button v-if="props.row.status === 'Review'" class="action-button cancel" @click="cancelTransaction(props.row.id)">Cancel</button>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </section>
        <section class="pagination-section" v-if="!loading">
          <div>
            Per page
            <select v-model="perPage" @change="setPages()">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
              </li>
            </ul>
          </nav>
        </section>
      </div>
      <div :class="{'trans-sub-cof-box' : true, 'cof-box-active' : activeInfoReversals}">
        <section  class="content-tlist" v-if="!loading">
          <el-table class="content-table" :data="reversals" @row-click="reversalsTableClick" ref="reversalsTable">
            <el-table-column type="expand">
              <template slot-scope="props">
                <div class="popup-header" v-for="selectedTransaction in [[props.row]]">
                  <h3>Transaction</h3>
                  <h3 v-if="selectedTransaction">{{ selectedTransaction[0].transactionId }}</h3>
                  <h3 v-if="selectedTransaction">Status: {{ selectedTransaction[0].status }}</h3>
                </div>
                <template v-for="selectedTransaction in [[props.row]]">
                  <div class="scrollable">
                    <div class="information">
                      <template v-if="selectedTransaction">
                        <div>
                          <p><b>Account</b>: ****{{ selectedTransaction[0].accountId.substr(-3) }}</p>
                          <p><b>Created</b>: {{ selectedTransaction[0].createdAt }}</p>
                          <p><b>Entry Type</b>: {{ selectedTransaction[0].entryType }}</p>
                          <p><b>Transaction Amount</b>:
                            <template v-if="selectedTransaction[0].currencyType == 'Assets'">{{ selectedTransaction[0].assetAmount ? parseFloat(selectedTransaction[0].assetAmount) : '' }}</template>
                            <template v-else>{{ selectedTransaction[0].amount | balance }}</template>
                          </p>
                        </div>
                        <div>
                          <p><b>Completed By</b>: {{ selectedTransaction[0].completedBy }}</p>
                          <p><b>Transaction Type</b>: {{ transactionTypeText[selectedTransaction[0].transactionType] }}</p>
                        </div>
                      </template>
                    </div>
                    <div class="popup-header details">
                      <h3>Details</h3>
                    </div>
                      <template v-if="selectedTransaction">
                        <template v-if="selectedTransaction[0].entryType == 'Deposit' && selectedTransaction[0].transactionType == 'Asset'">
                          <p><b>{{ getCurrencyName(selectedTransaction[0].currencyName) }} Deposit Wallet Address</b>:</p>
                          <p>{{ selectedTransaction[0].contributionIncomingWalletAddress }}</p>
                          <p>&nbsp;</p>
                        </template>
                        <template v-if="selectedTransaction[0].entryType != 'Deposit' && selectedTransaction[0].transactionType == 'Asset'">
                          <p><b>Pay to</b>: {{ selectedTransaction[0].label }}</p>
                          <p><b>Pay to Wallet Address</b>: {{ selectedTransaction[0].outgoingWalletAddress }}</p>
                          <p><b>Destination Tag</b>: {{ selectedTransaction[0].tag }}</p>
                          <p>&nbsp;</p>
                        </template>
                        <div class="information">
                          <div>
                            <template v-if="selectedTransaction[0].entryType == 'Deposit'">
                              <template v-if="selectedTransaction[0].transactionType == 'checks'">
                                <p><b>Payer</b>: <template v-if="selectedTransaction[0].remoteDepositPayor">{{ selectedTransaction[0].remoteDepositPayor.nameOfSender }}</template></p>
                                <br/>
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].remoteDepositPayor">
                                  <p>{{ selectedTransaction[0].remoteDepositPayor.address }}</p>
                                  <p>{{ selectedTransaction[0].remoteDepositPayor.city }}, {{ selectedTransaction[0].remoteDepositPayor.state }}, {{ selectedTransaction[0].remoteDepositPayor.country }}, {{ selectedTransaction[0].remoteDepositPayor.postal }}</p>
                                </template>
                                <br/>
                                <p><b>Check Front</b>: <a class="image-link" :href="`/files/transactions/${selectedTransaction[0].id}/${selectedTransaction[0].checkImageFront}`" target="_blank">{{ selectedTransaction[0].checkImageFront }}</a></p>
                                <p><b>Check Bank</b>: <a class="image-link" :href="`/files/transactions/${selectedTransaction[0].id}/${selectedTransaction[0].checkImageBack}`" target="_blank">{{ selectedTransaction[0].checkImageBack }}</a></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'Asset'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                <br/>
                                <p><b>Note</b>: Send only {{ getCurrencyName(selectedTransaction[0].currencyName) }} to this wallet address. Any other asset type sent may be lost and unrecoverable.</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'acc_to_acc'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'DebitCard'">
                                <p><b>MERCHANT</b></p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantName }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCity }}, {{ selectedTransaction[0].merchant.merchant.merchantState }}, {{ selectedTransaction[0].merchant.merchant.merchantCountry }}, {{ selectedTransaction[0].merchant.merchant.postalCode }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCategory }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else>
                                <template v-if="selectedTransaction[0].provider == 'Solid' || selectedTransaction[0].provider == 'Solid Test'">
                                  <template v-if="selectedTransaction[0].transactionType == 'ach'">
                                    <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                    <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                    <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">name: {{ selectedTransaction[0].settlementDetails.name }}, routingNumber: {{ selectedTransaction[0].settlementDetails.routingNumber }}, bankName: {{ selectedTransaction[0].settlementDetails.bankName }}</template></p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                    <br/>
                                    <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                    <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                  </template>
                                  <template v-else-if="selectedTransaction[0].transactionType == 'domesticWire' || selectedTransaction[0].transactionType == 'internationalWire'">
                                    <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }} name: {{ selectedTransaction[0].settlementDetails.name }}</template></p>
                                    <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                    <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">routingNumber: {{ selectedTransaction[0].settlementDetails.routingNumber }}, Address Line1: {{ selectedTransaction[0].settlementDetails.line1 }}, Address Line2: {{ selectedTransaction[0].settlementDetails.line2 }}, city: {{ selectedTransaction[0].settlementDetails.city }}, state: {{ selectedTransaction[0].settlementDetails.state }}, country: {{ selectedTransaction[0].settlementDetails.country }}, postal: {{ selectedTransaction[0].settlementDetails.postalCode }}, bankName: {{ selectedTransaction[0].settlementDetails.bankName }}</template></p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                    <br/>
                                    <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                    <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                  </template>
                                  <template v-else>
                                    <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                    <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                    <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                    <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                    <br/>
                                    <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                    <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                  </template>
                                </template>
                                <template v-else>
                                  <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                  <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                  <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                  <p>&nbsp;</p>
                                  <p>&nbsp;</p>
                                  <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                  <br/>
                                  <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                  <p><b>Reference</b>: {{ selectedTransaction[0].transactionReferenceCode }}</p>
                                </template>
                              </template>
                            </template>
                            <template v-else>
                              <template v-if="selectedTransaction[0].transactionType == 'international_wires' || selectedTransaction[0].transactionType == 'internationalWire'">
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].bankName }}</p>
                                <p><b>Swift</b>: {{ selectedTransaction[0].swift }}</p>
                                <br/>
                                <p><b>INTERMEDIARY BANK</b></p>
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].intermediaryBankName }}</p>
                                <p><b>Routing</b>: {{ selectedTransaction[0].intermediaryBankRoutingNumber }}</p>
                                <p><b>SWIFT</b>: {{ selectedTransaction[0].intermediaryBankSwiftCode }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].intermediaryBankReference }}</p>
                                <p><b>Address</b>:</p>
                                <p>{{ selectedTransaction[0].intermediaryAddress.address }}</p>
                                <p>{{ selectedTransaction[0].intermediaryAddress.city }}, {{ selectedTransaction[0].intermediaryAddress.state }}, {{ selectedTransaction[0].intermediaryAddress.country }}, {{ selectedTransaction[0].intermediaryAddress.postal }}</p>
                                <br/>
                                <p><b>BENEFICIARY</b></p>
                                <p><b>Account Name</b>: {{ selectedTransaction[0].accountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].accountNumber }}</p>
                                <p><b>Account Type</b>: {{ selectedTransaction[0].accountType }}</p>
                                <!--<p><b>IBAN</b>: {{ selectedTransaction[0].iban }}</p>-->
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].address">
                                  <p>{{ selectedTransaction[0].address.address }}</p>
                                  <p> {{ selectedTransaction[0].address.city }}, {{ selectedTransaction[0].address.state }}, {{ selectedTransaction[0].address.country }}, {{ selectedTransaction[0].address.postal }}</p>
                                </template>
                                <br/>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'checks'">
                                <p><b>Payee</b>: {{ selectedTransaction[0].payee }}</p>
                                <br/>
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].address">
                                  <p>{{ selectedTransaction[0].address.address }}</p>
                                  <p>{{ selectedTransaction[0].address.city }}, {{ selectedTransaction[0].address.state }}, {{ selectedTransaction[0].address.country }}, {{ selectedTransaction[0].address.postal }}</p>
                                </template>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'ach'">
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].bankName }}</p>
                                <p><b>Routing</b>: {{ selectedTransaction[0].routingNumber }}</p>
                                <br/>
                                <p><b>BENEFICIARY</b></p>
                                <p><b>Account Name</b>: {{ selectedTransaction[0].accountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].accountNumber }}</p>
                                <p><b>Account Type</b>: {{ selectedTransaction[0].accountType }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'domestic_wire' || selectedTransaction[0].transactionType == 'domesticWire'">
                                <p><b>Bank Name</b>: {{ selectedTransaction[0].bankName }}</p>
                                <p><b>Routing Number</b>: {{ selectedTransaction[0].routingNumber }}</p>
                                <br/>
                                <p><b>BENEFICIARY</b></p>
                                <p><b>Account Name</b>: {{ selectedTransaction[0].accountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].accountNumber }}</p>
                                <p><b>Account Type</b>: {{ selectedTransaction[0].accountType }}</p>
                                <p><b>Address</b>:</p>
                                <template v-if="selectedTransaction[0].address">
                                  <p>{{ selectedTransaction[0].address.address }}</p>
                                  <p>{{ selectedTransaction[0].address.city }}, {{ selectedTransaction[0].address.state }}, {{ selectedTransaction[0].address.country }}, {{ selectedTransaction[0].address.postal }}</p>
                                </template>
                                <br/>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'Asset'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'acc_to_acc'">
                                <p><b>Account Name</b>: {{ selectedTransaction[0].toAccountName }}</p>
                                <p><b>Account Number</b>: {{ selectedTransaction[0].toAccountId? getAccountNumber(selectedTransaction[0].toAccountId) : '' }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'Trade'">
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'System'">
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else-if="selectedTransaction[0].transactionType == 'DebitCard'">
                                <p><b>MERCHANT</b></p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantName }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCity }}, {{ selectedTransaction[0].merchant.merchant.merchantState }}, {{ selectedTransaction[0].merchant.merchant.merchantCountry }}, {{ selectedTransaction[0].merchant.merchant.postalCode }}</p>
                                <p>{{ selectedTransaction[0].merchant.merchant.merchantCategory }}</p>
                                <br/>
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                              <template v-else>
                                <p><b>Account Number</b>: ****{{ selectedTransaction[0].toAccountId? selectedTransaction[0].toAccountId.substr(4, 3) : '' }}
                                <p><b>Memo</b>: {{ selectedTransaction[0].memo }}</p>
                                <p><b>Reference</b>: {{ selectedTransaction[0].reference }}</p>
                              </template>
                            </template>
                          </div>
                          <div>
                            <template v-if="selectedTransaction[0].entryType == 'Deposit'">
                              <template v-if="selectedTransaction[0].transactionType == 'domestic_wire' || selectedTransaction[0].transactionType == 'international_wires' || selectedTransaction[0].transactionType == 'ach' || selectedTransaction[0].transactionType == 'Asset' || selectedTransaction[0].transactionType == 'acc_to_acc' || selectedTransaction[0].transactionType == 'Trade' || selectedTransaction[0].transactionType == 'domesticWire' || selectedTransaction[0].transactionType == 'internationalWire'">

                              </template>
                              <template v-else>
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                              </template>
                            </template>
                            <template v-else>
                              <template v-if="selectedTransaction[0].transactionType == 'Asset' || selectedTransaction[0].transactionType == 'Trade'">

                              </template>
                              <template v-else>
                                <p><b>Source</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].fundsSourceName }}</template></p>
                                <p><b>Settled</b>: <template v-if="selectedTransaction[0].settledAt">{{ selectedTransaction[0].settledAt | date }}</template></p>
                                <p><b>Settled Details</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].settlementDetails }}</template></p>
                                <p>&nbsp;</p>
                                <p>&nbsp;</p>
                                <p><b>Special Instructions</b>: <template v-if="selectedTransaction[0]">{{ selectedTransaction[0].specialInstructions }}</template></p>
                              </template>
                            </template>
                          </div>
                        </div>
                      </template>
                    <!-- Place for details -->
                    <div class="popup-header notes">
                      <h3>Notes</h3>
                      <button v-if="addNote" class="action-button save add-note-button" @click="saveNote(selectedTransaction[0])">Save</button>
                    </div>
                    <div class="content-form" v-if="addNote">
                      <div class="form-row form-max">
                        <form-field-text v-model="note" />
                      </div>
                    </div>
                    <table class="content-table">
                      <tr>
                        <th style="padding-left: 0;">Date/Time</th>
                        <th>Added By</th>
                        <th>Note</th>
                      </tr>
                      <template v-if="selectedTransaction">
                        <tr v-for="(item, i) in selectedTransaction[0].notes" :key="item.id">
                          <td style="padding-left: 0;"><span>Date/Time</span>{{ item.noteTime | date }}</td>
                          <td><span>Added By</span>{{ item.addedBy }}</td>
                          <td><span>Note</span>{{ item.note }}</td>
                        </tr>
                      </template>
                    </table>
                  </div>
                  <div class="popup-header notes">
                    <h3>Options</h3>
                  </div>
                  <div class="buttons">
                    <div class="optionBtns" v-if="selectedTransaction">
                      <template v-if="$hasPerm('approve-transaction')">
                        <button v-if="!isCustomer && selectedTransaction[0].status === 'Review'" class="approve" @click="approvePopupTransaction(selectedTransaction[0].id)">Approve</button>
                      </template>
                      <template v-if="!isCustomer">
                        <template v-if="perms">
                          <button v-if="selectedTransaction[0].status === 'Pending' || selectedTransaction[0].status === 'Review'" class="cancel cancel-action" @click="cancelPopupTransaction(selectedTransaction[0].id)">Cancel</button>
                        </template>
                      </template>
                      <template v-else>
                        <button v-if="selectedTransaction[0].status === 'Pending' || selectedTransaction[0].status === 'Review'" class="cancel cancel-action" @click="cancelPopupTransaction(selectedTransaction[0].id)">Cancel</button>
                      </template>
                    </div>
                    <button class="main" @click="print(selectedTransaction[0].id)">Print</button>
                  </div>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="Date" min-width="7">
              <template slot-scope="props">
                <p>{{ props.row.reversedAt | date('MM/D/YYYY') }}<br/>
                {{ props.row.reversedAt | date('hh:mm A') }}</p>
              </template>
            </el-table-column>
            <el-table-column label="Account" min-width="10">
              <template slot-scope="props">
                <a v-if="!isCustomer" :href="`/system-customers/registrations/${props.row.regId}?tab=Accounts`">{{ props.row.accountId }}</a>
                <template v-else>{{ props.row.accountId }}</template>
              </template>
            </el-table-column>
            <el-table-column label="Number" min-width="7">
              <template slot-scope="props">
                {{ props.row.transactionId }}
              </template>
            </el-table-column>
            <!-- <el-table-column label="Entry" min-width="6">
              <template slot-scope="props">
                {{ props.row.entryType == 'Deposit'? 'DEP' : 'W/D' }}
              </template>
            </el-table-column> -->
            <el-table-column label="Type" min-width="8">
              <template slot-scope="props">
                {{ transactionTypeText[props.row.transactionType] }}
              </template>
            </el-table-column>
            <el-table-column
              label="Currency" min-width="6"
              prop="currencyName">
            </el-table-column>
            <el-table-column label="Amount" min-width="10">
              <template slot-scope="props">
                <template v-if="props.row.currencyType == 'Assets'">
                  <template v-if="props.row.entryType == 'Deposit'">
                    <label style="color: green;">+ {{ parseFloat(props.row.assetAmount) }}</label>
                  </template>
                  <template v-else>
                    <label style="color: red;">- {{ parseFloat(props.row.assetAmount) }}</label>
                  </template>
                </template>
                <template v-else>
                  <template v-if="props.row.entryType == 'Deposit'">
                    <label style="color: green;">+ {{ props.row.currencySymbol }} {{ props.row.amount | balance_without_symbol }}</label>
                  </template>
                  <template v-else>
                    <label style="color: red;">- {{ props.row.currencySymbol }} {{ props.row.amount | balance_without_symbol }}</label>
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column label="Balance" min-width="8">
              <template slot-scope="props">
                <template v-if="props.row.currencyType == 'Assets'">{{ parseFloat(props.row.availableAssetsBalance) }}</template>
                <template v-else>{{ props.row.currencySymbol }} {{ props.row.availableFundsBalance | balance_without_symbol }}</template>
              </template>
            </el-table-column>
            <el-table-column label="Status" min-width="7">
              <template slot-scope="props">
                <em :class="statusColorClasses[props.row.status]">{{ props.row.status }}</em>
              </template>
            </el-table-column>
            <el-table-column
              label="Reference" min-width="17"
              prop="reference">
            </el-table-column>
            <el-table-column label="Options" min-width="14">
              <template slot-scope="props">
                <template v-if="$hasPerm('approve-transaction')">
                  <template v-if="props.row.provider == 'Solid' || props.row.provider == 'Solid Test'">
                    <button v-if="!isCustomer && props.row.status === 'Review'" class="action-button save" @click="approveSolidTransaction(item, props.row.id)">Approve</button>
                  </template>
                  <template v-else>
                    <button v-if="!isCustomer && props.row.status === 'Review'" class="action-button save" @click="approveTransaction(props.row.id)">Approve</button>
                  </template>
                </template>
                <template v-if="props.row.provider == 'Prime Trust' || props.row.provider == 'Prime Trust Test'">
                  <template v-if="!isCustomer">
                    <template v-if="perms">
                      <template v-if="props.row.transactionType == 'Asset'">
                        <button v-if="props.row.status === 'Pending' || props.row.status === 'Review'" class="action-button cancel" @click="cancelAssetTransaction(props.row.id)">Cancel</button>
                      </template>
                      <template v-else>
                        <button v-if="props.row.status === 'Pending' || props.row.status === 'Review'" class="action-button cancel" @click="cancelTransaction(props.row.id)">Cancel</button>
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <template v-if="props.row.transactionType == 'Asset'">
                      <button v-if="props.row.status === 'Pending' || props.row.status === 'Review'" class="action-button cancel" @click="cancelAssetTransaction(props.row.id)">Cancel</button>
                    </template>
                    <template v-else>
                      <button v-if="props.row.status === 'Pending' || props.row.status === 'Review'" class="action-button cancel" @click="cancelTransaction(props.row.id)">Cancel</button>
                    </template>
                  </template>
                  <template v-if="props.row.status === 'Pending' && props.row.disbursementAuthorizationsStatus === 'pending'">
                    <button v-if="!isCustomer" class="action-button cancel" @click="authRetry(props.row.id)">Auth</button>
                    <button v-else class="action-button cancel" disabled>Auth</button>
                  </template>
                </template>
                <template v-else>
                  <button v-if="props.row.status === 'Review'" class="action-button cancel" @click="cancelTransaction(props.row.id)">Cancel</button>
                </template>
              </template>
            </el-table-column>
          </el-table>
        </section>
        <section class="pagination-section" v-if="!loading">
          <div>
            Per page
            <select v-model="perPage" @change="setPages()">
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="75">75</option>
              <option value="100">100</option>
              <option value="150">150</option>
              <option value="200">200</option>
            </select>
          </div>
          <nav aria-label="Page navigation example">
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="page-link" v-if="page != 1" @click="page--"> Previous </button>
              </li>
              <li class="page-item">
                <button type="button" class="page-link" v-for="pageNumber in pages.slice(page-1, page+5)" :key="pageNumber" @click="page = pageNumber"> {{pageNumber}} </button>
              </li>
              <li class="page-item">
                <button type="button" @click="page++" v-if="page < pages.length" class="page-link"> Next </button>
              </li>
            </ul>
          </nav>
        </section>
      </div>
    </div>
    <popup :open.sync="addConfirmModal" :width="400">
      <div class="popup-header">
        <h3>Confirmation</h3>
      </div>
        <div class="scrollable">
          <div class="content-form">
            <div class="form-row">
              <p v-if="confirmSuccess" class="transaction-status">Transaction Cancelled</p>
              <p v-else class="transaction-status">Error: Unable to Cancel Transaction</p>
            </div>
          </div>
        </div>
        <div class="buttons">
          <button class="second" @click="addConfirmModal = false">Close</button>
        </div>
    </popup>
    <popup :open.sync="addApproveModal" :width="400">
      <div class="popup-header">
        <h3>Confirmation</h3>
      </div>
      <div class="scrollable">
        <div class="content-form">
          <div class="form-row">
            <p v-if="approveSuccess" class="transaction-status">Transaction Approved</p>
            <p v-else class="transaction-status">Error: Unable to Approve Transaction</p>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button class="second" @click="addApproveModal = false">Close</button>
      </div>
    </popup>
    <popup :open.sync="downloadModal">
      <div class="popup-header">
        <h3>Download</h3>
      </div>
        <div class="scrollable">
          <h1 class="text-center">Choose the Download Format</h1>
          <div class="content-form text-center">
            <div class="form-row form-split depositType">
              <form-field-select value="Excel" label="File Type" :options="[{id: 'Excel', text: 'Excel'}]" required />
            </div>
          </div>
          <div class="">
            <button class="main" @click="downloadExcel()">Download</button>
            <button class="second" @click="downloadModal = false">Cancel</button>
          </div>
        </div>
    </popup>
    <transactions-filters v-if="openedFilters" :filters="filters" @apply="applyFilters($event)" @close="openedFilters = false" />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import MnbSelect from '../../components/ui/forms/Select'
import VueEnglishdatepicker from 'vue-englishdatepicker';
import DateTimePicker from 'vue-vanilla-datetime-picker';
import TransactionsFilters from './filters/TransactionsFilters'
import AppliedFilters from '../../components/common/AppliedFilters'

export default {
  name: 'Transactions',

  components: {
    MnbSelect,
    VueEnglishdatepicker,
    DateTimePicker,
    AppliedFilters,
    TransactionsFilters
  },

  data () {
    return {
      openedFilters: false,
      filters: null,

      page: 1,
      perPage: 25,
      pages: [],
      totalNumber: 0,
      totalFundsNumber: 0,
      totalFundsDepositNumber: 0,
      totalFundsWithdrawalNumber: 0,
      totalAssetsNumber: 0,
      totalAssetsDepositNumber: 0,
      totalAssetsWithdrawalNumber: 0,
      totalAmount: 0,
      totalDepositAmount: 0,
      totalWithdrawalAmount: 0,
      totalAssetsAmount: 0,
      totalAssetsDepositAmount: 0,
      totalAssetsWithdrawalAmount: 0,

      addConfirmModal: false,
      confirmSuccess: true,
      addApproveModal: false,
      approveSuccess: true,
      downloadModal: false,

      selectedTransaction: null,
      addNote: false,
      note: null,
      loading: false,
      systemMaintenanceStatus: window.maintenance,
      activeInfo: true,
      activeInfoReversals: false,
      reversals: null,
      totalReversals: 0,
      today: moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD"),
      perms: window.appData.currentUser?.role?.perms?.includes('cancel-transaction') || window.appData.currentUser?.perms?.includes('cancel-transaction'),
      customEnv: appData.customEnv,
    }
  },

  computed: {
    appliedFilters () {
      const filterNames = {
        entryType: 'Entry Type',
        depositType: 'Transaction Type',
        transactionId: 'Transaction ID',
        accountId: 'Account Number',
        amount: 'Amount',
        status: 'Status',
        currencyType: 'Currency Type',
        currencyName: 'Currency',
      }
      
      if (!this.filters) return []

      let appliedFilters = Object.keys(this.filters).filter(key => ![null, ''].includes(this.filters[key]))
      const hasDateFilters = appliedFilters.includes('fromDate') || appliedFilters.includes('toDate')
      appliedFilters = appliedFilters.filter(key => key !== 'fromDate' && key !== 'toDate').map(key => ({
        key,
        text: `${filterNames[key]}: ${this.getFilterValue(key, this.filters[key])}`
      }))

      if (hasDateFilters) {
        appliedFilters.push({
          key: 'date',
          text: `Date Range: ${this.getDateText()}`
        })
      }

      return appliedFilters
    },

    currentUser () {
      return window.appData.currentUser
    },

    isCustomer () {
      return this.currentUser.isCustomer
    },

    reg () {
      return this.$store.getters['registrations/get'](this.currentUser.regId)
    },

    currencies () {
      let data = window.currencies || []
      return data
    },

    transactions () {
      let data =  this.$store.state.transactions.data || []
      this.totalNumber = this.$store.state.transactions.count || 0
      return data
    },

    filteredData () {
      return this.transactions;
    },

    transactionTypeText(){
      return {
        checks: 'Check',
        ach: 'ACH',
        domestic_wire: 'Domestic Wire',
        domesticWire: 'Domestic Wire',
        international_wires: 'International Wire',
        internationalWire: 'International Wire',
        acc_to_acc: 'Account Transfer',
        Asset: 'Asset',
        Trade: 'Trade',
        System: 'System',
        DebitCard: 'Debit Card',
      }
    },

    statusColorClasses () {
      return {
        New: 'c1',
        Pending: 'c2',
        pending: 'c2',
        Review: 'c2',
        'On Hold': 'c2',
        Denied: 'c4',
        Complete: 'c1',
        Reversed: 'c3',
        Cancelled: 'c3',
        Error: 'c3',
        Declined: 'c3'
      }
    },
  },

  watch: {
    appliedFilters (appliedFilters) {
      if (!appliedFilters.length) {
        this.refreshTransactions()
      }
    }
  },

  async created () {
    this.refreshReversals();
    this.refreshTransactions();
  },

  methods: {
    getDateText () {
      const from = this.filters.fromDate
      const to = this.filters.toDate

      let text = ''
      if (from) text = `From ${moment(from).format('LL')} `
      if (to) text += `To ${moment(to).format('LL')}`
      return text
    },

    clearFilters () {
      this.filters = null
    },

    getFilterValue (key, value) {
      if (key === 'currencyName') return this.getCurrencyName(value)
      if (key === 'amount') return window.formatMoney(value)

      if (key === 'entryType') return window.entryTypes[value]
      if (key === 'currencyType') return window.currencyTypes[value]
      if (key === 'status') return window.transactionStatuses[value]

      if (key === 'depositType') return window.transactionTypes[value]

      const dateFilters = ['fromDate', 'toDate']
      if (dateFilters.includes(key)) return moment(value).format('LL')

      return value
    },

    removeFilter (filter) {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    resetFilters () {
      if (filter.key === 'date') {
        this.$delete(this.filters, 'fromDate')
        this.$delete(this.filters, 'toDate')
        return;
      }

      this.$delete(this.filters, filter.key)
    },

    applyFilters (filters) {
      this.filters = filters
      this.openedFilters = false
    },

    setPages () {
      let numberOfPages = 0;
      if(this.activeInfo) {
        numberOfPages = Math.ceil(this.totalNumber / this.perPage);
      }

      if(this.activeInfoReversals) {
        numberOfPages = Math.ceil(this.totalReversals / this.perPage);
      }
      this.pages = [];
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },

    async paginate (data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = (page * perPage) - perPage;
      let to = (page * perPage);
      return data.slice(from, to);
    },

    async cancelTransaction(id) {
      if(confirm(`Are you sure you want to Cancel this transaction?`)) {
        const loader = this.$loading.show()
        try {
          const status = (await api.action("Transactions", id, "Cancel")).action
          if(status.error)
            this.confirmSuccess = false
          else
            this.confrimSucess = true
          this.addConfirmModal = true
          await this.refreshTransactions();
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async cancelAssetTransaction(id) {
      if(confirm(`Are you sure you want to Cancel this transaction?`)) {
        const loader = this.$loading.show()
        try {
          const status = (await api.action("Transactions", id, "CancelAsset")).action
          if(status.error)
            this.confirmSuccess = false
          else
            this.confrimSucess = true
          this.addConfirmModal = true
          await this.refreshTransactions();
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async authRetry(id) {
      const loader = this.$loading.show()
      try {
        const status = (await api.action("Transactions", id, "AuthRetry")).action
        await this.refreshTransactions();
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async cancelPopupTransaction(id) {
      if(confirm(`Are you sure you want to Cancel this transaction?`)) {
        const loader = this.$loading.show()
        try {
          const status = (await api.action("Transactions", id, "Cancel")).action
          if (status.error)
            this.confirmSuccess = false
          else
            this.confrimSucess = true
          await this.refreshTransactions();
          this.selectedTransaction = this.transactions.filter(item => item.id === id)
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async approveTransaction(id) {
      if(confirm(`Are you sure you want to Approve this transaction?`)) {
        const loader = this.$loading.show()
        try {
          const status = (await api.action("Transactions", id, "Approve")).action
          if (status.error)
            this.approveSuccess = false
          this.addApproveModal = true
          await this.refreshTransactions();
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async approveSolidTransaction(item, id) {
      if(confirm(`Are you sure you want to Approve this transaction?`)) {
        const loader = this.$loading.show()
        try {
          let status
          if(item.remoteDepositPayor) {
            status = (await api.action("Transactions", id, "RemoteDepositSolidApi")).action
          }else{
            status = (await api.action("Transactions", id, "Approve")).action
          }
          if (status.error)
            this.approveSuccess = false

          this.addApproveModal = true
          await this.refreshTransactions();
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async approvePopupTransaction(id) {
      if(confirm(`Are you sure you want to Approve this transaction?`)) {
        const loader = this.$loading.show()
        try {
          const status = (await api.action("Transactions", id, "Approve")).action
          if (status.error)
            this.approveSuccess = false
          await this.refreshTransactions();
          this.selectedTransaction = this.transactions.filter(item => item.id === id)
        } catch (e) {
          this.$toast.error(window.errorMessage)
        } finally {
          loader.hide()
        }
      }
    },

    async updateTrasactions() {
      const loader = this.$loading.show()
      try {
        const status = (await api.action("Transactions", "new", "UpdateTransactions")).action
        await this.refreshTransactions();
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    async saveNote (transaction) {
      transaction=transaction || this.selectedTransaction[0];
      const payload = {
        transactionId: transaction.id,
        addedBy: window.appData.currentUser.firstName + " " + window.appData.currentUser.lastName,
        note: this.note,
        noteTime: 'CURRENT_TIMESTAMP'
      }

      await this.$store.dispatch('transactionNotes/create', payload)
      this.addNote = false
      this.note = null
      await this.refreshTransactions();
      this.selectedTransaction = this.transactions.filter(item => item.id === transaction.id)
    },

    async refreshReversals () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      let orders = {}
      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        params.accountId = ['IS NOT NULL'];
        params.reversedAt=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")];
        orders = ['reversedAt:DESC', 'transactionId:DESC'];
      } else {
        params.accountId = ['IS NOT NULL'];
        params.status = 'Reversed';
        if (this.filters.fromDate)
          params.reversedAtAfter=[">=", this.filters.fromDate+" 00:00:00", "reversedAt"];
        if (this.filters.toDate)
          params.reversedAtBefore=["<=", this.filters.toDate+" 23:59:59", "reversedAt"];
        if (this.filters.depositType) {
          params.transactionType = this.filters.depositType
        }
        if (this.filters.entryType) {
          params.entryType = this.filters.entryType
        }
        if (this.filters.profileId) {
          params.profileId = this.filters.profileId
        }
        if (this.filters.transactionId) {
          params.transactionId = this.filters.transactionId
        }
        if (this.filters.accountId) {
          params.accountId = this.filters.accountId
        }
        if (this.filters.amount) {
          params.amount = this.filters.amount
        }
        if (this.filters.status) {
          if(this.filters.status != 'Reversed') {
            params.status = 'Null';
          }else{
            params.status = 'Reversed';
          }
        }else{
          params.status = 'Reversed';
        }
        if (this.filters.currencyType) {
          params.currencyType = this.filters.currencyType
        }
        if (this.filters.currencyName) {
          params.currencyName = this.filters.currencyName
        }
        orders = ['transactionId:DESC'];
      }
      if (this.isCustomer) {
        const reg = await this.$store.dispatch('registrations/get', this.currentUser.regId)
        params.accountId=['OR']
        reg?.accounts.forEach(account => {
          params.accountId.push(account.accountNumber)
        })
      }
      let action = (await api.list('Transactions', params, orders, this.perPage, (this.page-1) * this.perPage ))
      this.reversals = action.items
      this.totalReversals = action.count
      this.loading=false;
      loader.hide()
    },

    async refreshTransactions () {
      const loader = this.$loading.show()
      this.loading=true;
      let params = {}
      let orders = {}
      if (!this.filters || !Object.values(this.filters).filter(val => val).length) {
        params.accountId = ['IS NOT NULL'];
        //params.createdAt=[">=", moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")];
        params.status = ["='Pending' OR status='Review' OR createdAt>'"+moment("00:00:00", "HH:mm:ss").format("YYYY-MM-DD HH:mm:ss")+"'"];
        orders = ['createdAt:DESC', 'transactionId:DESC'];
      } else {
        params.accountId = ['IS NOT NULL'];
        if (this.filters.fromDate)
          params.createdAtAfter=[">=", this.filters.fromDate+" 00:00:00", "createdAt"];
        if (this.filters.toDate)
          params.createdAtBefore=["<=", this.filters.toDate+" 23:59:59", "createdAt"];
        if (this.filters.depositType) {
          params.transactionType = this.filters.depositType
        }
        if (this.filters.entryType) {
          params.entryType = this.filters.entryType
        }
        if (this.filters.profileId) {
          params.profileId = this.filters.profileId
        }
        if (this.filters.transactionId) {
          params.transactionId = this.filters.transactionId
        }
        if (this.filters.accountId) {
          params.accountId = this.filters.accountId
        }
        if (this.filters.amount) {
          params.amount = this.filters.amount
        }
        if (this.filters.status) {
          params.status = this.filters.status
        }
        if (this.filters.currencyType) {
          params.currencyType = this.filters.currencyType
        }
        if (this.filters.currencyName) {
          params.currencyName = this.filters.currencyName
        }
        orders = ['transactionId:DESC'];
      }
      if (this.isCustomer) {
        const reg = await this.$store.dispatch('registrations/get', this.currentUser.regId)
        if (reg && reg.accounts.length) {
          params.accountId=['OR']
          reg?.accounts.forEach(account => {
            params.accountId.push(account.accountNumber)
          })
        }
      }
      var items = (await api.list("Transactions", params, {}, {}, {}, ['profileId', 'accountId', 'transactionId', 'status', 'provider', 'providerTransactionId', 'transactionType', 'completedBy', 'transactionFee', 'totalAmount', 'instructions', 'fundsTransferId', 'bankName', 'accountName', 'accountType', 'routingNumber', 'regName', 'profileNumber', 'walletAddress', 'currencySymbol', 'qPaymentReference', 'toAccountName'])).items;
      this.totalAmount = 0;
      this.totalDepositAmount = 0;
      this.totalWithdrawalAmount = 0;
      this.totalAssetsAmount = 0;
      this.totalAssetsDepositAmount = 0;
      this.totalAssetsWithdrawalAmount = 0;
      this.totalFundsNumber = 0;
      this.totalFundsDepositNumber = 0;
      this.totalFundsWithdrawalNumber = 0;
      this.totalAssetsNumber = 0;
      this.totalAssetsDepositNumber = 0;
      this.totalAssetsWithdrawalNumber = 0;
      for(var i = 0; i < items.length; i++) {
        if(items[i].currencyType == 'Assets') {
          if(items[i].entryType == 'Deposit') { 
            this.totalAssetsDepositNumber++
            if(parseFloat(items[i].assetAmount))
              this.totalAssetsDepositAmount += parseFloat(items[i].assetAmount)
          }else{
            this.totalAssetsWithdrawalNumber++
            if(parseFloat(items[i].assetAmount))
              this.totalAssetsWithdrawalAmount += parseFloat(items[i].assetAmount)
          }
        }else{
          if(items[i].entryType == 'Deposit') {
            this.totalFundsDepositNumber++
            if(parseFloat(items[i].amount))
              this.totalDepositAmount += parseFloat(items[i].amount)
          }else{
            this.totalFundsWithdrawalNumber++
            if(parseFloat(items[i].amount))
              this.totalWithdrawalAmount += parseFloat(items[i].amount)
          }
        }
      }
      await this.$store.dispatch('transactions/load', { order: orders, params, limit: this.perPage, start: (this.page-1) * this.perPage })


      this.loading=false;
      loader.hide()
    },

    getCurrencyName (currencyName) {
      let currencies = window.currencies || []
      currencies = currencies.filter(currency => {
        return currency.currencyNickname === currencyName
      })
      return currencies[0]? currencies[0].name.charAt(0).toUpperCase() + currencies[0].name.slice(1) : null
    },

    async print (id) {
      const loader = this.$loading.show()
      try {
        const status = (await api.action("Transactions", id, "Print")).action
        window.open("/files/transactions/"+id+"/"+id+"-transaction.pdf");
      } catch (e) {
        this.$toast.error(window.errorMessage)
      } finally {
        loader.hide()
      }
    },

    getAccountNumber(accountNumber) {
      let number = ''
      for(var i = 0; i < accountNumber.length-3; i++) {
       number = number + '*'
      }
      number = number + accountNumber.substr(-3)
      return number
    },

    downloadExcel() {
      if(this.filters){
        window.open(`/export_transactions_report/${this.filters.fromDate}/${this.filters.toDate}/${this.filters.depositType}/${this.filters.entryType}/${this.filters.transactionId}/${this.filters.accountId}/${this.filters.amount}/${this.filters.status}/${this.filters.currencyType}/${this.filters.currencyName}`);
      }else{
        window.open("/export_transactions_report///////////");
      }
      this.downloadModal = false
    },

    transactionsTableClick(row) {
      this.$refs.transactionsTable.toggleRowExpansion(row);
    },
    reversalsTableClick(row) {
      this.$refs.reversalsTable.toggleRowExpansion(row);
    },
  },

  watch: {
    transactions () {
      this.setPages();
    },
    appliedFilters () {
      this.refreshReversals();
      this.refreshTransactions();
    },

    activeInfo() {
      this.setPages();
    },

    activeInfoReversals() {
      this.setPages();
    },

    page() {
      this.refreshReversals();
      this.refreshTransactions();
    },

    perPage() {
      if(this.page == 1){
        this.refreshReversals();
        this.refreshTransactions();
      }else{
        this.page = 1
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  tr {
    td {
      padding: 5px;
    }
  }
}
.buttons {
  margin-top: 30px;
}
.add-note-button {
  min-width: 50px !important;
}

.information {
  display: flex;
  justify-content: space-between;

  div {
    width: 50%; 
  }
}

.buttons {
  display: flex;
  justify-content: space-between;

  .optionBtns .main {
    margin-left: 15px; 
  }
}
.form-max {
  div {
    width: 100%;
  }
}
button.cancel {
  background: #666;
  color: white;
}
button.cancel.cancel-action {
  padding: 0 16px;
  cursor: pointer;
}
button.approve {
  background: #67c23a;
  color: white;
}
.btn-approve {
  background: #67c23a ;
  color: white;
}

.search-item {
  input {
    max-width: 110px;
  }
}

.image-link {
  color: #51aeff;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}

.trans-cof-box {
  width: 50%;
  position: relative;
  height: 51px;
  overflow: hidden;
  padding: 0 16px;
  margin: 15px 30px;
  background: #f1f4f7;
  border-radius: 16px;

  .accordion-headline {
    margin-bottom: 0;
    font-weight: bold;
    font-size: 18px;
    width: 100%;
    height: 19px;
    padding: 16px 0;
    cursor: pointer;
  }

  .total-number {
    position: absolute;
    top: 15px;
    right: 40%;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
  }
}

  .trans-sub-cof-box {
    position: relative;
    height: 0px;
    overflow: hidden;
    padding: 0 16px;
    margin: 0px 30px;
    background: #f1f4f7;
    border-radius: 16px;
  }
.trans-cof-box::after {
  content: "";
  display: block;
  position: absolute;
  top: 25px;
  right: 30px;
  left: unset;
  width: 13px;
  height: 1px;
  transition: .25s ease-out;
  background-color: #0480c6;
  transform: rotate(90deg);
}
.trans-cof-box::before {
  content: "";
  display: block;
  position: absolute;
  top: 25px;
  right: 30px;
  width: 13px;
  height: 1px;
  transition: .25s ease-out;
  background-color: #0480c6;
}
.trans-cof-box-active::before {
  transform: rotate(180deg);
}
.trans-cof-box-active::after {
  transform: rotate(180deg);
}
.accordion .cof-box-active {
  height: auto;
}

.summary-table td {
  padding: 3px 20px;
}

.summary-part {
  position: absolute;
  top: 15px;
  right: 41%;
}

.content-table.smaller {
  tr td, tr th {
    height: 20px;
    padding: 5px 15px;
  }
}

.content-tlist {
  border: none;
}

.depositType {
  justify-content: center;
}

</style>
